// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../../../../node_modules/_css-loader@0.28.7@css-loader/index.js??ref--8-oneOf-1-1!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/loaders/stylePostLoader.js!../../../../../node_modules/_postcss-loader@2.0.9@postcss-loader/lib/index.js??ref--8-oneOf-1-2!../../../../../node_modules/_sass-loader@7.3.1@sass-loader/dist/cjs.js??ref--8-oneOf-1-3!../../../../../node_modules/_cache-loader@4.1.0@cache-loader/dist/cjs.js??ref--0-0!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/index.js??vue-loader-options!./major.vue?vue&type=style&index=1&id=f1a07b52&lang=scss");
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add the styles to the DOM
var update = require("!../../../../../node_modules/_vue-style-loader@3.0.3@vue-style-loader/lib/addStylesClient.js")("a6df7aac", content, false);
// Hot Module Replacement
if(module.hot) {
 // When the styles change, update the <style> tags
 if(!content.locals) {
   module.hot.accept("!!../../../../../node_modules/_css-loader@0.28.7@css-loader/index.js??ref--8-oneOf-1-1!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/loaders/stylePostLoader.js!../../../../../node_modules/_postcss-loader@2.0.9@postcss-loader/lib/index.js??ref--8-oneOf-1-2!../../../../../node_modules/_sass-loader@7.3.1@sass-loader/dist/cjs.js??ref--8-oneOf-1-3!../../../../../node_modules/_cache-loader@4.1.0@cache-loader/dist/cjs.js??ref--0-0!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/index.js??vue-loader-options!./major.vue?vue&type=style&index=1&id=f1a07b52&lang=scss", function() {
     var newContent = require("!!../../../../../node_modules/_css-loader@0.28.7@css-loader/index.js??ref--8-oneOf-1-1!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/loaders/stylePostLoader.js!../../../../../node_modules/_postcss-loader@2.0.9@postcss-loader/lib/index.js??ref--8-oneOf-1-2!../../../../../node_modules/_sass-loader@7.3.1@sass-loader/dist/cjs.js??ref--8-oneOf-1-3!../../../../../node_modules/_cache-loader@4.1.0@cache-loader/dist/cjs.js??ref--0-0!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/index.js??vue-loader-options!./major.vue?vue&type=style&index=1&id=f1a07b52&lang=scss");
     if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];
     update(newContent);
   });
 }
 // When the module is disposed, remove the <style> tags
 module.hot.dispose(function() { update(); });
}